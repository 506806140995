import * as React from 'react';
import { styled } from '../stitches.config';
import { Link } from '../ui/Link';
import { P } from '../ui/Paragraph';

import * as SitemapConfig from '../domain/vo/SitemapConfig';

const Content = styled('div', {
  fontSize: '14px',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginTop: '16px',
});

const LogoContainer = styled('div', {
  margin: '26px 0',
  height: '200px',
  width: '200px',
});

export const Copyright: React.VFC = () => {
  const year = React.useMemo(() => new Date().getFullYear(), []);

  return (
    <P style={{ marginTop: '32px' }}>© 2014-{year} DroidKaigi Committee</P>
  );
};

export const Layout: React.FC = ({ children }) => {
  const pastKaigis = React.useMemo(() => SitemapConfig.GetPastKaigis(), []);

  return (
    <Content>
      <LogoContainer>
        <img src="/assets/logo.svg" alt="" />
      </LogoContainer>

      {children}

      <Resources label="PastKaigis" configs={pastKaigis} newTab={false} />
      <Resources
        label="Resources"
        configs={SitemapConfig.Hostings}
        newTab={true}
      />

      <Copyright />
    </Content>
  );
};

const ResourceLabel = styled('div', {
  margin: '32px 0 0 0',
  padding: '4px 0',
});

const ResourceContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'center',
});

const ResourceDivider = styled('span', {
  padding: '0 4px',
});

interface ResourcesProps {
  label: string;
  configs: SitemapConfig.SitemapConfig[];
  newTab: boolean;
}

const Resources: React.VFC<ResourcesProps> = ({ label, configs, newTab }) => {
  return (
    <>
      <ResourceLabel>{label}</ResourceLabel>
      <ResourceContainer>
        {configs.map((c, i) => {
          if (i === 0) {
            return (
              <a
                href={c.baseURL}
                key={c.path}
                target="_blank"
                rel="noopener noreferrer"
              >
                {c.shortName}
              </a>
            );
          } else {
            return (
              <div key={c.path}>
                <ResourceDivider>/</ResourceDivider>
                <Link href={c.baseURL} newTab={newTab}>
                  {c.shortName}
                </Link>
              </div>
            );
          }
        })}
      </ResourceContainer>
    </>
  );
};
