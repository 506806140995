import * as React from 'react';

interface LinkProps {
  href: string;
  newTab: boolean;
}

export const Link: React.FC<LinkProps> = ({ href, newTab, children }) => {
  if (newTab) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  } else {
    return <a href={href}>{children}</a>;
  }
};
