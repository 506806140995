import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { globalCss, styled } from './stitches.config';
import { ExternalRedirect } from './components/ExternalRedirect';
import { PageNotFound } from './components/PageNotFound';

import * as SitemapConfig from './domain/vo/SitemapConfig';

const AppContainer = styled('div', {
  maxWidth: '540px',
  padding: '12px 15px 25px',
  margin: '0 auto',
});

export const App: React.VFC = () => {
  globalCss();

  return (
    <AppContainer>
      <Switch>
        <Route exact path="/">
          <ExternalRedirect
            baseURL={SitemapConfig.TopLevel.baseURL}
            path={'/'}
          />
        </Route>
        {SitemapConfig.All.map((config, index) => {
          return (
            <Route path={config.path} key={`${config.path}-${index}`}>
              <ExternalRedirect baseURL={config.baseURL} path={config.path} />
            </Route>
          );
        })}
        <Route component={PageNotFound} />
      </Switch>
    </AppContainer>
  );
};
