import * as React from 'react';
import { styled } from '../stitches.config';
import { Link } from '../ui/Link';
import { Layout } from './Layout';

import * as SitemapConfig from '../domain/vo/SitemapConfig';

const H2 = styled('h2', {
  fontSize: '26px',
  lineHeight: '1.4',
});

const MessageContainer = styled('div', {
  marginTop: '20px',
});

const UnderlineText = styled('span', {
  textDecoration: 'underline',
  textDecorationColor: '$highContrast',
});

export const PageNotFound: React.VFC = () => {
  const pastKaigis = React.useMemo(() => SitemapConfig.GetPastKaigis(), []);

  return (
    <Layout>
      <H2>404 Page not found.</H2>

      {pastKaigis.includes(SitemapConfig.TopLevel) ? (
        <></>
      ) : (
        <MessageContainer>
          Are you looking for this year's event?{' '}
          <Link href={SitemapConfig.TopLevel.baseURL} newTab={false}>
            <UnderlineText>Check it out!</UnderlineText>
          </Link>
        </MessageContainer>
      )}
    </Layout>
  );
};
