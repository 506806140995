import { createCss, StitchesCss } from '@stitches/react';

export const stitchesConfig = createCss({
  theme: {
    colors: {
      pageBackground: 'rgb(0,0,0)',
      highContrast: 'rgb(255,255,255)',
    },
    fonts: {
      mono: 'monospace',
      robot: 'robot',
    },
  },
});

export type CSS = StitchesCss<typeof stitchesConfig>;

export const {
  styled,
  theme,
  keyframes,
  global: createGlobalCss,
} = stitchesConfig;

export const globalCss = createGlobalCss({
  // unset all styles on interactive elements
  'button, input, select, textarea, a, area': {
    all: 'unset',
  },
  // normalize behavior on all elements
  '*, *::before, *::after, button, input, select, textarea, a, area': {
    margin: 0,
    border: 0,
    padding: 0,
    boxSizing: 'inherit',
    font: 'inherit',
    fontWeight: 'inherit',
    textAlign: 'inherit',
    lineHeight: 'inherit',
    wordBreak: 'inherit',
    color: 'inherit',
    background: 'transparent',
    outline: 'none',
    WebkitTapHighlightColor: 'transparent',
  },
  a: {
    cursor: 'pointer',
  },
  'a:hover': {
    textDecoration: 'underline',
    textDecorationColor: '$highContrast',
  },
  // set base styles for the app
  body: {
    color: '$highContrast',
    fontFamily: 'system-ui, Helvetica Neue, sans-serif',
    // use word-break instead of "overflow-wrap: anywhere" because of Safari support
    wordBreak: 'break-word',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontSize: '16px',
    boxSizing: 'border-box',
    textSizeAdjust: 'none',
  },
  code: {
    fontFamily: '$mono',
  },
  // pass down height: 100% to the #root div
  'body, html': {
    height: '100%',
  },
  '#root': {
    minHeight: '100%',
    backgroundColor: '$pageBackground',
  },
});
