import * as Sitemap from '../../sitemap';

export interface SitemapConfig {
  path: string;
  shortName: string;
  longName: string;
  startDate?: string;
  endDate?: string;
  baseURL: string;
  resourceType?: string;
}

export const All: SitemapConfig[] = Object.keys(Sitemap.configs).map((k) => {
  return {
    ...(Sitemap.configs as any)[k],
    path: k,
  };
});

export const TopLevel: SitemapConfig = All.find(
  (e) => e.path === Sitemap.topLevelKey,
)!;

export const GetPastKaigis: () => SitemapConfig[] = () => {
  const now = new Date();

  return All.filter(
    (c) =>
      c.resourceType === 'yearly-kaigi' &&
      c.endDate &&
      Date.parse(c.endDate) < now.getTime(),
  );
};

export const Hostings: SitemapConfig[] = All.filter(
  (c) => c.resourceType === 'hosting',
);
