import * as React from 'react';
import { styled } from '../stitches.config';
import { useLocation } from 'react-router-dom';
import { Layout } from './Layout';
import { P } from '../ui/Paragraph';
import { Link } from '../ui/Link';

interface Props {
  baseURL: string;
  path: string;
}

const H2 = styled('h2', {
  fontSize: '26px',
  lineHeight: '1.4',
});

const UnderlineText = styled('span', {
  textDecoration: 'underline',
  textDecorationColor: '$highContrast',
});

export const ExternalRedirect: React.VFC<Props> = ({ baseURL, path }) => {
  const { pathname, search } = useLocation();
  const redirectTo = React.useMemo(() => {
    // 念の為、startsWith チェック
    const newPathname =
      path.length > 0 && pathname.startsWith(path)
        ? pathname.substring(path.length)
        : pathname;

    return baseURL + newPathname + search;
  }, [pathname, search, path, baseURL]);

  React.useEffect(() => {
    window.location.href = redirectTo;
  }, [redirectTo]);

  return (
    <Layout>
      <H2>{`Redirecting...`}</H2>

      <P>
        Click{' '}
        <Link href={redirectTo} newTab={false}>
          <UnderlineText>here</UnderlineText>
        </Link>{' '}
        if the page does not redirect automatically.
      </P>
    </Layout>
  );
};
