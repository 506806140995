// 最新の年次イベントに相当する key of configs です。ティザーがオープンして以降、設置してください
const topLevelKey = '/2023';

// config は次のルールに従ってください
//
// shortName: string! / DroidKaigiのコンテクストがあればわかるような短めの名前
// longName: string! / DroidKaigiのコンテクストなしにわかる名前
// startDate?: string (yyyy-MM-dd) / 開催開始年月日。endDate を入れた場合は必須
// endDate?: string (yyyy-MM-dd) / 開催終了年月日。startDate を入れた場合は必須です
// hasEnglish?: boolean / true なら `/en` も sitemap に追加する
// resourceType?: 'yearly-kaigi' | 'hosting' / 年次イベントが yearly-kaigi、自分たちでホストしてる特定年度に限らない媒体が hosting です
// baseURL: string (URL) / 最後にスラッシュはつけないこと

const verifyConfig = (path, config) => {
  const errors = [];

  if (!path.startsWith('/')) {
    errors.push('path はスラッシュ始まりにしてください');
  }

  if (!config.shortName || config.shortName.length === 0) {
    errors.push('shortName は必須です');
  }

  if (!config.longName || config.longName.length === 0) {
    errors.push('longName は必須です');
  }

  if (config.startDate) {
    Date.parse(config.startDate); // error が投げられるのでそのまま

    if (!config.endDate) {
      errors.push('startDate を設定する場合、endDate は必須です');
    }
  }

  if (config.endDate) {
    Date.parse(config.endDate); // error が投げられるのでそのまま

    if (!config.startDate) {
      errors.push('endDate を設定する場合、startDate は必須です');
    }
  }

  if (
    config.resourceType &&
    !['yearly-kaigi', 'hosting'].includes(config.resourceType)
  ) {
    errors.push('resourceType はyearly-kaigi, hostingのいずれかにしてください');
  }

  if (!config.baseURL || config.baseURL.length === 0) {
    errors.push('baseURL は必須です');
  } else if (config.baseURL[config.baseURL.length - 1] === '/') {
    errors.push('baseURL はスラッシュで終わらないようにしてください');
  }

  if (errors.length > 0) {
    errors.forEach((e) => console.error(e));
    throw new Error(`${path} がエラーを含んでいます`);
  }
};

const configs = {
  '/2015': {
    shortName: '2015',
    longName: 'DroidKaigi 2015',
    startDate: '2015-04-25',
    endDate: '2015-04-25',
    hasEnglish: false,
    resourceType: 'yearly-kaigi',
    baseURL: 'https://conf-2015.droidkaigi.jp',
  },
  '/2016': {
    shortName: '2016',
    longName: 'DroidKaigi 2016',
    startDate: '2016-02-18',
    endDate: '2016-02-19',
    hasEnglish: false,
    resourceType: 'yearly-kaigi',
    baseURL: 'https://conf-2016.droidkaigi.jp',
  },
  '/2017': {
    shortName: '2017',
    longName: 'DroidKaigi 2017',
    startDate: '2017-03-09',
    endDate: '2017-03-10',
    hasEnglish: true,
    resourceType: 'yearly-kaigi',
    baseURL: 'https://conf-2017.droidkaigi.jp',
  },
  '/2018': {
    shortName: '2018',
    longName: 'DroidKaigi 2018',
    startDate: '2018-02-08',
    endDate: '2018-02-09',
    hasEnglish: true,
    resourceType: 'yearly-kaigi',
    baseURL: 'https://conf-2018.droidkaigi.jp',
  },
  '/2019': {
    shortName: '2019',
    longName: 'DroidKaigi 2019',
    startDate: '2019-02-07',
    endDate: '2019-02-08',
    hasEnglish: true,
    resourceType: 'yearly-kaigi',
    baseURL: 'https://conf-2019.droidkaigi.jp',
  },
  '/2020': {
    shortName: '2020',
    longName: 'DroidKaigi 2020',
    startDate: '2020-02-20',
    endDate: '2020-02-21',
    hasEnglish: true,
    resourceType: 'yearly-kaigi',
    baseURL: 'https://conf-2020.droidkaigi.jp',
  },
  '/2020lite': {
    shortName: '2020 Lite',
    longName: 'DroidKaigi 2020 Lite',
    startDate: '2020-08-27',
    endDate: '2020-08-29',
    hasEnglish: true,
    resourceType: 'yearly-kaigi',
    baseURL: 'https://conf-2020-lite.droidkaigi.jp',
  },
  '/2021': {
    shortName: '2021',
    longName: 'DroidKaigi 2021',
    startDate: '2021-10-18',
    endDate: '2021-10-19',
    hasEnglish: true,
    resourceType: 'yearly-kaigi',
    baseURL: 'https://conf-2021.droidkaigi.jp',
  },
  '/2022': {
    shortName: '2022',
    longName: 'DroidKaigi 2022',
    startDate: '2022-10-05',
    endDate: '2022-10-07',
    hasEnglish: true,
    resourceType: 'yearly-kaigi',
    baseURL: 'https://conf-2022.droidkaigi.jp',
  },
  '/2023': {
    shortName: '2023',
    longName: 'DroidKaigi 2023',
    startDate: '2023-09-14',
    endDate: '2023-09-16',
    hasEnglish: true,
    resourceType: 'yearly-kaigi',
    baseURL: 'https://conf-2023.droidkaigi.jp',
  },
  '/ninjas': {
    shortName: 'Ninjas',
    longName: 'DroidKaigi Ninjas',
    hasEnglish: true,
    baseURL: 'https://conf-2022-ninjas.droidkaigi.jp',
  },
  '/portal': {
    shortName: 'Portal',
    longName: 'DroidKaigi Portal',
    hasEnglish: false,
    resourceType: 'hosting',
    baseURL: 'https://portal.droidkaigi.jp',
  },
  '/fm': {
    shortName: 'Podcast',
    longName: 'DroidKaigi.fm',
    hasEnglish: false,
    resourceType: 'hosting',
    baseURL: 'https://fm.droidkaigi.jp',
  },
  '/medium': {
    shortName: 'Medium',
    longName: 'Medium',
    hasEnglish: false,
    resourceType: 'hosting',
    baseURL: 'https://medium.com/droidkaigi',
  },
  '/youtube': {
    shortName: 'YouTube',
    longName: 'YouTube Channel',
    hasEnglish: false,
    resourceType: 'hosting',
    baseURL: 'https://www.youtube.com/channel/UCgK6L-PKx2OZBuhrQ6mmQZw',
  },
};

const baseURL = 'https://droidkaigi.jp/';

const siteMap = [baseURL];

for (const path in configs) {
  const config = configs[path];

  verifyConfig(path, config);

  siteMap.push(baseURL + '?' + path);

  if (config.hasEnglish) {
    siteMap.push(baseURL + '?' + path + '/en');
  }
}

module.exports = {
  siteMap,
  configs,
  topLevelKey,
};
